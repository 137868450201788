import React, { useState, useEffect } from "react";
import ten from '../../assets/Images/vroomstack10v2.webp';
import twofive from '../../assets/Images/vroomstack25v2.webp';
import fivezero from '../../assets/Images/vroomstack50v2.webp';
import dimx from '../../assets/Images/dimx.webp';
import doodles from '../../assets/Images/doodles.webp';
import kemba from '../../assets/Images/Kemba.mp4';
import williams from '../../assets/Images/Williams.mp4';
import tatum from '../../assets/Images/Tatum.mp4';
import ballerz from '../../assets/Images/ballerz.webp';
import flunks from '../../assets/Images/flunks.webp';
import flovatar from '../../assets/Images/flovatar.webp';
import shoes from '../../assets/Images/shoes.webp';
import drivPP1 from '../../assets/Images/Driverz_Puff_Palz-1.webp'
import './vroomShop.css';
import * as fcl from '@blocto/fcl';
import { transferVroom } from "../../Cadence/Transactions/transferVroom";
import { getAllTresors } from '../../Cadence/Scripts/getAllTresors';
import { createNewBidRemoveOldData } from '../../Cadence/Transactions/createNewBidRemoveOldData';
import { createBidVroomTresor } from '../../Cadence/Transactions/createBidVroomTresor';
import { transferVroomSubmitBid } from '../../Cadence/Transactions/transferVroomSubmitBid';
import { purchaseVroom } from '../../Cadence/Vroom/transactions/purchaseVroom';
import { getBids } from '../../Cadence/Scripts/getBids';
import { getCCPurchaseData } from '../../Cadence/Vroom/scripts/getCCPurchaseData';
import { getTresorDetails } from '../../Cadence/Vroom/scripts/getTresorDetails';
import { checkVroomBalance } from '../../Cadence/Scripts/checkVroomBalance';
import { checkFlowBalance} from '../../Cadence/Scripts/checkFlowBalance';
import { getCarClubListingDetails } from "../../Cadence/Vroom/scripts/getCarClubDetails";
import { purchaseCarClubListing } from "../../Cadence/Vroom/transactions/purchaseCarClubListing";
import singleCC from '../../assets/Images/singleCCSale.png';
import packCC from '../../assets/Images/packCCSale.png';
import { db1 } from '../../firebaseDRL';
import { collection, getDocs, orderBy, updateDoc, arrayUnion, where, limit, query, deleteDoc, addDoc, getDoc, doc} from "firebase/firestore";
const videoUrl = "https://gateway.pinata.cloud/ipfs/Qmb2FFHiULyPYcX2DrvHxa3ME2dQiNbNHAwySqVom5kqt2/%285425%29.mp4";

//Flunks - #7640 - Jock, Most likely to yell WAGMI in class, Graduated. Starting Bid 1500 VROOM.
//Froginals - Flow Ordinal #2575. Starting Bid 1000 VROOM. 
//K1 Speed Race Gift Package - Includes 1 Annual License, 2 Arrive & Drive Races, Exclusive T-Shirt & Gift Tin. Starting Bid 6500 VROOM.
//Driverz x Puff Palz Exclusive - 1/1 Helmet. Starting Bid 2420 VROOM. 
//Driverz x Puff Palz Exclusive - 1/1 Helmet. Starting Bid 2420 VROOM. 
//NBA TopShot - Rare, 2022 All-Star Game Series 3, LeBron James - #308/1006. Starting Bid 5000 VROOM.
//NBA TopShot - Rare, For the Win Series 4, Bradley Beal - #426/777. Starting Bid 800 VROOM.
//NBA TopShot - Rare, Throwdowns Series 2, Aaron Gordon - #309/1722. Starting Bid 800 VROOM. 
//Ballerz - #8142, Skills: Dunk 60 / Shot 78 / Play 71 / Def 88 / Ovr 74.25. Starting Bid 2500 VROOM.


function Vroom() {

  // const [amountTit, setAmountTit] = useState("");
  const [user, setUser] = useState({ loggedIn: null });  
  //const [amountFlow, setAmountFlow] = useState("");
  const [userAddress, setUserAddress] = useState(null);
  const [tenPriceTresors, settenPriceTresors] = useState([]);
  const [twoFivePriceTresors, settwoFivePriceTresors] = useState([]);
  const [fiveZeroPriceTresors, setfiveZeroPriceTresors] = useState([]);
  const [bal, setBal] = useState(null);
  const [flowBal, setFlowBal] = useState(null);
  const [listings4070, setListings4070] = useState([]);
  const [listings12100, setListings12100] = useState([]);
  const [isSoldOut4070, setIsSoldOut4070] = useState(false);
  const [isSoldOut12100, setIsSoldOut12100] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isProcessing1, setIsProcessing1] = useState(false);
  const [isProcessing2, setIsProcessing2] = useState(false);
  const [isProcessing3, setIsProcessing3] = useState(false);
  const [isProcessing4, setIsProcessing4] = useState(false);
  const [isProcessing5, setIsProcessing5] = useState(false);
  const [isProcessing6, setIsProcessing6] = useState(false);
  const [isProcessing7, setIsProcessing7] = useState(false);
  const [isProcessing8, setIsProcessing8] = useState(false);
  const [isProcessing9, setIsProcessing9] = useState(false);
  const [isProcessing10, setIsProcessing10] = useState(false);
  const [isProcessing11, setIsProcessing11] = useState(false);

  const [dots, setDots] =useState("")
  const [singlePurchases, setSinglePurchases] = useState(0);
  const [packPurchases, setPackPurchases] = useState(0);

  const [bid, setBid] = useState(''); // State for handling the bid input
  const [feedbackMessage, setFeedbackMessage] = useState(''); // Optional: For user feedback
  const [userHighestBid, setUserHighestBid] = useState(null); // Placeholder for user's highest bid
  const [currentHighestBid, setCurrentHighestBid] = useState('');
  const [bidOptions, setBidOptions] = useState([]);

  //Dimension X Silent Auction
  const [bidDim, setBidDim] = useState(''); // State for handling the bid input
  const [userHighestBidDim, setUserHighestBidDim] = useState(null); // Placeholder for user's highest bid
  const [currentHighestBidDim, setCurrentHighestBidDim] = useState('');
  const [bidOptionsDim, setBidOptionsDim] = useState([]);

  //Doodles Silent Auction
  const [bidDood, setBidDood] = useState(''); // State for handling the bid input
  const [userHighestBidDood, setUserHighestBidDood] = useState(null); // Placeholder for user's highest bid
  const [currentHighestBidDood, setCurrentHighestBidDood] = useState('');
  const [bidOptionsDood, setBidOptionsDood] = useState([]);

  //Top Shot Kemba Silent Auction
  const [bidTSK, setBidTSK] = useState(''); // State for handling the bid input
  const [userHighestBidTSK, setUserHighestBidTSK] = useState(null); // Placeholder for user's highest bid
  const [currentHighestBidTSK, setCurrentHighestBidTSK] = useState('');
  const [bidOptionsTSK, setBidOptionsTSK] = useState([]);

  //Top Shot Williams Silent Auction
  const [bidTSW, setBidTSW] = useState(''); // State for handling the bid input
  const [userHighestBidTSW, setUserHighestBidTSW] = useState(null); // Placeholder for user's highest bid
  const [currentHighestBidTSW, setCurrentHighestBidTSW] = useState('');
  const [bidOptionsTSW, setBidOptionsTSW] = useState([]);

  //Top Shot Tatum Silent Auction
  const [bidTST, setBidTST] = useState(''); // State for handling the bid input
  const [userHighestBidTST, setUserHighestBidTST] = useState(null); // Placeholder for user's highest bid
  const [currentHighestBidTST, setCurrentHighestBidTST] = useState('');
  const [bidOptionsTST, setBidOptionsTST] = useState([]);

  //Ballerz Silent Auction
  const [bidballerz, setBidballerz] = useState(''); // State for handling the bid input
  const [userHighestBidballerz, setUserHighestBidballerz] = useState(null); // Placeholder for user's highest bid
  const [currentHighestBidballerz, setCurrentHighestBidballerz] = useState('');
  const [bidOptionsballerz, setBidOptionsballerz] = useState([]);

  //Flunks Silent Auction
  const [bidflunks, setBidflunks] = useState(''); // State for handling the bid input
  const [userHighestBidflunks, setUserHighestBidflunks] = useState(null); // Placeholder for user's highest bid
  const [currentHighestBidflunks, setCurrentHighestBidflunks] = useState('');
  const [bidOptionsflunks, setBidOptionsflunks] = useState([]);

  //Flovatar Silent Auction
  const [bidflovatar, setBidflovatar] = useState(''); // State for handling the bid input
  const [userHighestBidflovatar, setUserHighestBidflovatar] = useState(null); // Placeholder for user's highest bid
  const [currentHighestBidflovatar, setCurrentHighestBidflovatar] = useState('');
  const [bidOptionsflovatar, setBidOptionsflovatar] = useState([]);

  //Ballerz Shoes Silent Auction
  const [bidshoes, setBidshoes] = useState(''); // State for handling the bid input
  const [userHighestBidshoes, setUserHighestBidshoes] = useState(null); // Placeholder for user's highest bid
  const [currentHighestBidshoes, setCurrentHighestBidshoes] = useState('');
  const [bidOptionsshoes, setBidOptionsshoes] = useState([]);

  useEffect(() => {
    fcl.currentUser.subscribe((currentUser) => {
      console.log("Before setting user:", user);
      setUser(currentUser);
      console.log("After setting user:", currentUser);
      setUserAddress(currentUser.addr);
    });
  }, []);

  useEffect(() => {
    // Determine if any isProcessing state is true
    const anyProcessing = [isProcessing, isProcessing1, isProcessing2, isProcessing3, isProcessing4, isProcessing5, isProcessing6, isProcessing7, isProcessing8, isProcessing9, isProcessing10, isProcessing11].some(state => state);
  
    let intervalId;
    if (anyProcessing) {
      intervalId = setInterval(() => {
        // Update dots state to cycle through '', '.', '..', '...'
        setDots(dots => {
          switch (dots) {
            case '':
              return '.';
            case '.':
              return '..';
            case '..':
              return '...';
            default:
              return '';
          }
        });
      }, 500); // Adjust time as needed
    }
  
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [isProcessing, isProcessing1, isProcessing2, isProcessing3, isProcessing4, isProcessing5, isProcessing6, isProcessing7, isProcessing8, isProcessing9, isProcessing10, isProcessing11]); // Dependency array includes all isProcessingX variables
  
const getVroomBalance = async () => {
  if (user.loggedIn === null) return;
  console.log("Fetching VROOM balance for:", user?.addr);
  const bal = await fcl.query({
    cadence: checkVroomBalance,
    args: (arg, t) => [arg(user?.addr, t.Address)]
  });
  setBal(Number(bal).toFixed(2));
  console.log("New VROOM balance:", bal);
};

const getFlowBalance = async () => {
  if (user.loggedIn === null) return;
  console.log("Fetching FLOW balance for:", user?.addr);
  const flowBal = await fcl.query({
    cadence: checkFlowBalance,
    args: (arg, t) => [arg(user?.addr, t.Address)]
  });
  setFlowBal(Number(flowBal).toFixed(2));
  console.log("New FLOW balance:", flowBal);
};

useEffect(() => {
  const fetchBalances = async () => {
    if (user.loggedIn) {
      console.log("Fetching balances for:", user?.addr);
      await getVroomBalance(); // Wait for VROOM balance to be fetched
      console.log("VROOM balance fetched. Fetching FLOW balance...");
      await getFlowBalance(); // Only fetch FLOW balance after VROOM balance is fetched
      console.log("FLOW balance fetched.");
    }
  };

  fetchBalances();
}, [user]); // This effect depends on user state

const getPurchaseData = async () => {
  try {
    const result = await fcl.query({
      cadence: getCCPurchaseData,
      // args: (arg, t) => [], // Specify if your Cadence script requires them
    });

    let singleCount = 0;
    let packCount = 0;

    // Assuming 'result' is an object containing purchase records
    Object.values(result).forEach((purchase) => {
      if (purchase.userAddress === userAddress) {

        if (purchase.itemType === "Single") {
          console.log("You are here!!")
          singleCount += 1;
        } else if (purchase.itemType === "Pack") {
          packCount += 1;
        }
      }
    });

    // Update state variables
    setSinglePurchases(singleCount);
    setPackPurchases(packCount);

  } catch(error) {
    console.error("Error fetching purchase data:", error);
  }
}

useEffect(() => {
  getPurchaseData();

}, [user]);

useEffect(() => {
  console.log("Single Purchases: ", singlePurchases);
  console.log("Pack Purchases: ", packPurchases);
}, [singlePurchases, packPurchases]); // This useEffect depends on singlePurchases and packPurchases

async function fetchBidTresors() {
  try {
    const result = await fcl.query({
      cadence: getTresorDetails
    });

  } catch (error) {}

};


async function fetchTresorDetails() {
  try {
      const result = await fcl.query({
          cadence: getTresorDetails,
          args: (arg, t) => [arg("0xf887ece39166906e", t.Address)],
      });

      // Initialize arrays for each price category
      const ten = [], twoFive = [], fiveZero = [];

      // Sort tresor IDs into their respective arrays
      result.forEach(tresor => {
          switch (tresor.price) {
              case "10.00000000":
                  ten.push(tresor.tresorID);
                  break;
              case "25.00000000":
                  twoFive.push(tresor.tresorID);
                  break;
              case "50.00000000":
                  fiveZero.push(tresor.tresorID);
                  break;
              default:
                  // Handle other cases or log an error
                  console.log(`Unknown price category for Tresor ID ${tresor.tresorID}`);
          }
      });

      // Update state with sorted Tresor IDs
      settenPriceTresors(ten);
      settwoFivePriceTresors(twoFive);
      setfiveZeroPriceTresors(fiveZero);

      // Console logs for each price category
      console.log("Tresor IDs for 6: ", ten);
      console.log("Tresor IDs for 15: ", twoFive);
      console.log("Tresor IDs for 30: ", fiveZero);
  } catch (error) {
      console.error("Error fetching Tresor details:", error);
  }
}

useEffect(() => {
  // Existing code...
  fetchTresorDetails(); // Fetch Tresor details on component mount
}, []);

const transferFlow10 = async () => {
  //setAmountFlow("0.006");
  if (tenPriceTresors.length === 0) {
      console.error("No Tresors available for this price category");
      return;
  }
  const txid = await fcl.mutate({
      cadence: purchaseVroom,
      args: (arg, t) => [
          arg(tenPriceTresors[0], t.UInt64),
          arg("10.0", t.UFix64),
          arg("0xf887ece39166906e", t.Address)
      ],
      proposer: fcl.currentUser,
      payer: fcl.currentUser,
      authorizations: [fcl.currentUser],
      limit: 999,
  });
}

const transferFlow25 = async () => {
  //setAmountFlow("0.006");

  if (twoFivePriceTresors.length === 0) {
      console.error("No Tresors available for this price category");
      return;
  }
  const txid = await fcl.mutate({
      cadence: purchaseVroom,
      args: (arg, t) => [
          arg(twoFivePriceTresors[0], t.UInt64),
          arg("25.0", t.UFix64),
          arg("0xf887ece39166906e", t.Address)
      ],
      proposer: fcl.currentUser,
      payer: fcl.currentUser,
      authorizations: [fcl.currentUser],
      limit: 999,
  });
}

const transferFlow50 = async () => {
  //setAmountFlow("0.006");

  if (fiveZeroPriceTresors.length === 0) {
      console.error("No Tresors available for this price category");
      return;
  }
  const txid = await fcl.mutate({
      cadence: purchaseVroom,
      args: (arg, t) => [
          arg(fiveZeroPriceTresors[0], t.UInt64),
          arg("50.0", t.UFix64),
          arg("0xf887ece39166906e", t.Address)
      ],
      proposer: fcl.currentUser,
      payer: fcl.currentUser,
      authorizations: [fcl.currentUser],
      limit: 999,
  });
};

const purchase4070 = async () => {
  try {
    setIsProcessing10(true);
      console.log("Attempting to purchase at 3050.0 vroom...");
      const listingID = listings4070[0].listingId;
      const address = listings4070[0].storefrontAddress;
      console.log("ListingID: ", listingID, "Address Storefront: ", address);
      console.log("TX ID Input: ", typeof listingID, listingID);

      
      const txid = await fcl.mutate({
          cadence: purchaseCarClubListing,
          args: (arg, t) => [
              arg(listingID, t.UInt64),
              arg(address, t.Address),
              arg(userAddress, t.Address),
              arg("Single", t.String),
              arg("Initial", t.String)
          ],
          proposer: fcl.currentUser,
          payer: fcl.currentUser,
          authorizations: [fcl.currentUser],
          limit: 999,
      });

      console.log(`Transaction ID: ${txid}`);
      await fcl.tx(txid).onceSealed();
      getCCPurchaseData();
      setIsProcessing10(false);
      console.log("Transaction sealed.");
      
  } catch (error) {
      console.error("Error in purchase3050:", error);
      setIsProcessing10(false);
      getCCPurchaseData();
      // Additional error handling can be done here
  }
};

const purchase12100 = async () => {
  try {
    setIsProcessing11(true);
      console.log("Attempting to purchase at 9000.0 vroom...");
      const listingID = listings12100[0].listingId;
      const address = listings12100[0].storefrontAddress;
      console.log("ListingID: ", listingID, "Address Storefront: ", address);
      console.log("TX ID Input: ", typeof listingID, listingID);

      
      const txid = await fcl.mutate({
          cadence: purchaseCarClubListing,
          args: (arg, t) => [
              arg(listingID, t.UInt64),
              arg(address, t.Address),
              arg(userAddress, t.Address),
              arg("Pack", t.String),
              arg("Initial", t.String)
          ],
          proposer: fcl.currentUser,
          payer: fcl.currentUser,
          authorizations: [fcl.currentUser],
          limit: 999,
      });

      console.log(`Transaction ID: ${txid}`);
      await fcl.tx(txid).onceSealed();
      console.log("Transaction sealed.");
      getCCPurchaseData();
      setIsProcessing11(false);

  } catch (error) {
      console.error("Error in purchase9000:", error);
      setIsProcessing11(false);
      getCCPurchaseData();
      // Additional error handling can be done here
  }
};

//*************************************************
//FETCH THE BID DATA FOR EACH SILENT AUCTION PIECE
//*************************************************

async function fetchBidDetails() {
  try {
    const result = await fcl.query({
      cadence: getBids,
      // args: (arg, t) => [], // Specify if your Cadence script requires them
    });

    // Initialize a map to hold sum of bids for each user by auction type
    let userBidsSumByAuctionType = {};
    // Track the highest bid for each auction type
    let highestBidByAuctionType = {};

    // Iterate over each bid in the results
    for (let key in result) {
      const { auctionType, userAddress, currentBid } = result[key];



      // Initialize data structures for each auction type if they don't exist
      if (!userBidsSumByAuctionType[auctionType]) {
        userBidsSumByAuctionType[auctionType] = {};
        highestBidByAuctionType[auctionType] = 0;
      }
      if (!userBidsSumByAuctionType[auctionType][userAddress]) {
        userBidsSumByAuctionType[auctionType][userAddress] = 0;
      }

      // Sum bids for each user by auction type
      userBidsSumByAuctionType[auctionType][userAddress] += parseFloat(currentBid);

      // Check if the current total bid for the user is the highest for the auction type
      highestBidByAuctionType[auctionType] = Math.max(highestBidByAuctionType[auctionType], userBidsSumByAuctionType[auctionType][userAddress]);
    }

    // Assuming you have a specific userAddress you're interested in for displaying user's highest bids
    let userHighestBids = {};
    for (let auctionType in userBidsSumByAuctionType) {
      userHighestBids[auctionType] = userBidsSumByAuctionType[auctionType][userAddress] || 0;
    }

    // Update UI states with either the highest bid or the starting bid if no bids have been made
    setCurrentHighestBid(highestBidByAuctionType['CCrare'] > 0 ? highestBidByAuctionType['CCrare'].toString() : STARTING_BID.toString());
    setUserHighestBid(userHighestBids['CCrare'] ? userHighestBids['CCrare'].toString() : "0");



  } catch (error) {
    console.error("Error fetching Bids:", error);
  }
}



// Ensure fetchBidDetails is called appropriately, likely within a useEffect hook
useEffect(() => {
  fetchBidDetails();
}, []); 

const STARTING_BID = 3000; // Starting bid set to 3000
const STARTING_BID_DIM = 2000; // Starting bid set to 3000
const STARTING_BID_DOOD = 2000;
const STARTING_BID_TSK = 1000;
const STARTING_BID_TSW = 1000;
const STARTING_BID_TST = 2000;
const STARTING_BID_ballerz = 2000;
const STARTING_BID_flunks = 500;
const STARTING_BID_flovatar = 500;
const STARTING_BID_shoes = 500;

//*************************************************
//BELOW IS CC RARE SILENT AUCTION LOGIC
//*************************************************

const handleTransferVroom = async (amount) => {
  try {
    setIsProcessing(true);
    console.log(amount);

    // Generating a 16 character alphanumeric string for txID
    const txID = [...Array(16)].map(() => Math.floor(Math.random() * 16).toString(16)).join('');

    // Formatting the current date as a string
    const date = new Date().toISOString();

    const TXid = await fcl.mutate({
      cadence: createNewBidRemoveOldData,
      args: (arg, t) => [
        arg("TresorID", t.UFix64),
        arg(amount, t.UFix64),
        arg("CCrare", t.String), // Assuming the auctionType is "CCrare", adjust as necessary
        arg(date, t.String),
        arg(txID, t.String)
      ],
      proposer: fcl.currentUser,
      payer: fcl.currentUser,
      authorizations: [fcl.currentUser],
      limit: 999,
    });
    console.log("Processing Transaction: ", TXid);
    await fcl.tx(TXid).onceSealed();
    setIsProcessing(false);
    fetchBidDetails();
    return TXid;
  } catch (error) {
    console.error("Error in handleTransferVroom:", error);
    setIsProcessing(false);
    return null; // Indicate failure
  }
};

// Then, use this function in your handleBidSubmit function appropriately.




useEffect(() => {
  console.log("Highest Bid: ", currentHighestBid);
  // Determine the base for increments
  const baseBid = currentHighestBid && parseFloat(currentHighestBid) > STARTING_BID ? parseFloat(currentHighestBid) : STARTING_BID;

  // Generate bid options
  const newBidOptions = Array.from({ length: 10 }, (_, index) => baseBid + ((index + 1) * 50));
  
  setBidOptions(newBidOptions);
}, [currentHighestBid]);






const handleBidSubmit = async (e) => {
  e.preventDefault();

  const newBidAmount = parseFloat(bid);
  const userCurrentHighestBid = parseFloat(userHighestBid) || 0; // Ensure this is a number, default to 0 if null

  // Calculate the difference to be transferred
  const bidDifference = newBidAmount - userCurrentHighestBid;

  try {
    // Proceed with transferring the bid difference
    const transactionId = await handleTransferVroom(bidDifference.toFixed(2)); // Format to 2 decimal places
    console.log("Transaction successful:", transactionId);
    fetchBidDetails(); // Refresh bid details to get the latest user's highest bid
  } catch (error) {
    console.error("Error during bid submission or transfer process: ", error);
    setFeedbackMessage("Failed to submit bid.");
  }

  setBid(''); // Clear the bid input after submission
};



//*************************************************
//ABOVE IS CAR CLUB SILENT AUCTION LOGIC
//*************************************************




const getListingDetails = async () => {
  try {
      const details = await fcl.query({
          cadence: getCarClubListingDetails,
          args: (arg, t) => [
            arg("0xf887ece39166906e", t.Address)
          ],
        });
  // Assuming the data structure is directly usable and price is a string
  // Adjust the comparison to account for string comparison and ensure prices are compared correctly
  const filtered4070 = details.filter(listing => parseFloat(listing.price) === 4070.0 && !listing.purchased);
  const filtered12100 = details.filter(listing => parseFloat(listing.price) === 12100.0 && !listing.purchased);

        setListings4070(filtered4070);
        setListings12100(filtered12100);

                      // Print the arrays to the console
          console.log("Listings Priced at 3050.0:", filtered4070);
          console.log("Listings Priced at 9000.0:", filtered12100);
  } catch(error) {};
}

useEffect(() => {
  getListingDetails();
}, []); 

  // Effect hook to update sold out status for 3050 listings
useEffect(() => {
  setIsSoldOut4070(listings4070.length === 0 || listings4070.every(listing => listing.purchased));
}, [listings4070]); // Dependency array includes listings3050 to re-run this effect when listings3050 changes

// Effect hook to update sold out status for 9000 listings
useEffect(() => {
  setIsSoldOut12100(listings12100.length === 0 || listings12100.every(listing => listing.purchased));
}, [listings12100]); // Dependency array includes listings9000 to re-run this effect when listings9000 changes


const handleButtonClick = async (e) => {
  e.preventDefault(); // Prevent default form submission behavior

  const bidAmount = parseFloat(bid);
  if (bidAmount > parseFloat(bal)) {
    // If bid amount is greater than balance, navigate to buy section
    window.location.hash = '#buy-vroom-section';
  } else {
    // Otherwise, attempt to place the bid
    await handleBidSubmit(e); // Assuming handleBidSubmit is adjusted to work with this call
  }
};

return(
  <>
  <div className="shop-main">
    <h1>$VROOM SHOP</h1>
  </div>

  <div className="section-divider-2"></div>
  <div className="shop-main">
    <div>
      <h2>Current Balances</h2>
    </div>
  </div>
  <div className="balances-container">
    <div className="balance">
      <h3>$VROOM Balance</h3>
      <p className="balance-amount">{bal}</p>
      {/* Button for navigating to the Buy $VROOM section */}
      <button className="button" onClick={() => window.location.hash = '#buy-vroom-section'}>ADD FUNDS</button>
    </div>
    <div className="balance">
      <h3>$FLOW Balance</h3>
      <p className="balance-amount">{flowBal}</p>
      {/* Button for adding FLOW funds via an external link */}
      <button className="button" onClick={() => window.open('https://www.moonpay.com/buy/flow', '_blank', 'noopener,noreferrer')}>ADD FUNDS</button>
    </div>
  </div>
  <div className="section-divider-2"></div>
  <div className="shop-main" id="buy-vroom-section">
    <div>
      <h2>$VROOM Packs</h2>
    </div>
  </div>
  <div className="shop-page">
    <div className="buy">
        <div className="price-tag">10 $FLOW</div>
        <img src={ten} className="coin-bag" alt="small bag of tokens"/>
        <p className="vroom-amount">1100 $VROOM</p>
        <button onClick={transferFlow10} className="button">BUY</button>
    </div>
    <div className="buy">
        <div className="price-tag">25 $FLOW</div>
        <img src={twofive} className="coin-bag" alt="small bag of tokens"/>
        <p className="vroom-amount">2800 $VROOM<br/><span className="bonus-text">BONUS 50</span></p>
        <button onClick={transferFlow25} className="button"> BUY</button>
    </div>
    <div className="buy">
        <div className="price-tag">50 $FLOW</div>
        <img src={fivezero} className="coin-bag" alt="small bag of tokens"/>
        <p className="vroom-amount">5650 $VROOM<br/><span className="bonus-text">BONUS 150</span></p>
        <button onClick={transferFlow50} className="button"> BUY</button>
    </div>
  </div>
  <div className="section-divider"></div>
    <div className="admin-2">
        Driverz Collectibles
    </div>
    <div className="pack-row">
    <div className="pack-column-1">
    <div className="price-tag">4,070 $VROOM</div>
      <img src={singleCC} alt="Car Club Collectible" />
      <h3>Car Club</h3>
      <h4>Car Club Collectible</h4>
      {isProcessing10 ? (
            <p>Processing{dots}</p>
          ) : (
        <button
          className='mint-button'
          onClick={() => {
            if (singlePurchases < 2) { // Assuming singlePurchases is the state tracking the count
              if (parseFloat(bal) >= 4070) {
                purchase4070();
              } else {
                window.location.hash = '#buy-vroom-section';
              }
            }
          }}
          disabled={isSoldOut4070 || singlePurchases >= 2}
        >
          {isSoldOut4070 ? 'SOLD OUT' : singlePurchases >= 2 ? 'Max Purchased' : parseFloat(bal) >= 4070 ? 'BUY' : 'ADD FUNDS'}
        </button>
          )}

    </div>

    <div className="pack-column-1">
    <div className="price-tag">12,100 $VROOM</div>
      <img src={packCC} alt="Toolbox Bundle" />
      <h3>Toolbox Bundle</h3>
      <h4>4 Car Club Collectibles</h4>
      {isProcessing11 ? (
            <p>Processing{dots}</p>
          ) : (
          <button
            className='mint-button'
            onClick={() => {
              if (packPurchases < 1) {
                if (!isSoldOut12100) {
                  if (parseFloat(bal) >= 12100) {
                    purchase12100();
                  } else {
                    window.location.hash = '#buy-vroom-section';
                  }
                }
              }
            }}
            disabled={isSoldOut12100 || packPurchases >= 1}
          >
            {isSoldOut12100 ? 'SOLD OUT' : packPurchases >= 1 ? 'Max Purchased' : parseFloat(bal) >= 12100 ? 'BUY' : 'ADD FUNDS'}
          </button>
      )}
    </div>

      </div> 
 {/*} <div className="section-divider"></div>
  <div className="admin-2">
      Auction House
  </div>
  <div className="auction-tiles-container">
    <div className="auction-tile-new">
      <div className="title-label">Rare Car Club #5425</div> 
      <div className="video-container">
          {/*<video className="silent-auction-video" loop muted autoPlay>
              <source src={videoUrl} type="video/mp4" />
              Your browser does not support the video tag.
    </video>
          <img src={drivPP1} alt="Toolbox Bundle" />
      </div>

      <div className="bid-container">
      <p>High Bid: 
          {currentHighestBid && parseFloat(currentHighestBid) >= STARTING_BID ? ` ${parseInt(currentHighestBid)} $VROOM` : ` ${STARTING_BID} $VROOM `}
      </p>

          {userHighestBid && <p>Your Bid: {parseInt(userHighestBid)} $VROOM</p>}
      </div>

      <div className="bid-container">
        <form onSubmit={handleButtonClick} className="bid-form"> 
          <select
            id="bidInput"
            className="select-bid"
            value={bid} // Make sure 'bid' state is properly managed in your component
            onChange={(e) => setBid(e.target.value)} // And 'setBid' updates it
          >

            <option value="">Select Bid</option>

            {bidOptions.map((option, index) => (
              <option key={index} value={option}>
                {option} $VROOM
              </option>
            ))}
          </select>
          {isProcessing ? (
            <p>Processing Bid{dots}</p>
          ) : (
          <button 
            className="mint-button"
            onClick={(e) => {
              e.preventDefault(); // Prevent form submission if navigating away
              if (parseFloat(bid) - parseFloat(userHighestBid) > parseFloat(bal)) {
                window.location.hash = '#buy-vroom-section'; // Navigate to add funds
              } else {
                handleBidSubmit(e); // Attempt to place bid
              }
            }}
            disabled={!bid || bid === ""}
          >
            {parseFloat(bid) - parseFloat(userHighestBid) > parseFloat(bal) ? "ADD FUNDS" : "PLACE BID"}
          </button>
          )}
        </form>
      </div>

    </div>
      

        </div> */}



</>

);



}

export default Vroom;
